import * as React from "react";
import { Helmet } from "react-helmet-async";

// The doc prop contains some metadata about the page being rendered that you can use.
const Wrapper = ({ children, doc }) => (
  <React.Fragment>
    <Helmet>
      <title>API Reference</title>
      <meta charSet="utf-8" />
      <meta property="og:description" content="Sign documents electronically." />
      <meta property="twitter:card" content="API Reference | SignPenFree Documentation" />
      <meta property="twitter:title" content="SignPenFree REST API Reference" />
      <meta property="twitter:description" content="Sign documents electronically." />
      <meta name="description" content="Sign documents electronically." />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:title" content="SignPenFree REST API Reference" />
      <meta property="og:site_name" content="SignPenFree Documentation" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://signpenfree.dev" />
      <meta
        property="og:image"
        content="https://signpenfree.dev/img/logo.png"
      />
      <meta property="og:image:width" content="490" />
      <meta property="og:image:height" content="87" />
      <meta property="og:locale" content="en" />
      <link rel="canonical" href="https://signpenfree.dev" />
      <link
        rel="icon"
        type="image/png"
        href="https://signpenfree.com/img/favicon.png"
      />
    </Helmet>
    {children}
  </React.Fragment>
);
export default Wrapper;
